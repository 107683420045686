import React from 'react';
// import TagManager from 'react-gtm-module'
import {
  Navbar,
  Connect,
  Loading,
  AccountDetail,
  MenuList,
  PreLoading,
  Footer,
  ScrollButton,
  HeaderText,
  QrCode,
  SocialFixed,
  AuthenticateForm,
  // TourBanner,
} from 'components';
import { useShowButtonScroll, useRouteTopPage } from 'services/useDevelopUI';
import { useStoreAccount } from 'queries/useLogin.query';
import { useAuthenticate } from 'services/useAuthenticate';
import { ToastContainer } from 'react-toastify';
import { Switch, Route } from 'react-router-dom';
import { useListenWallet } from 'services/useWalletProvider';
import './root.css';
import 'animate.css/animate.min.css';
import 'react-toastify/dist/ReactToastify.css';

const HomePage = React.lazy(() => import('./pages/Home'));
const Fusion = React.lazy(() => import('./pages/Fusion'));
// const ConvertPage = React.lazy(() => import('./pages/Convert'))
// const GuilScholarPage = React.lazy(() => import('./pages/Guild'))
// const ConvertPage = React.lazy(() => import('./pages/Convert'))
const GalleryPage = React.lazy(() => import('./pages/Gallery'));
const ComingPage = React.lazy(() => import('./components/Coming/Coming'));
// const ComingBuyPage = React.lazy(() => import('./components/Coming/ComingBuy'))
const WhitePaper = React.lazy(() => import('./pages/WhitePaper/WhitePaper'));
const Quests = React.lazy(() => import('./pages/Quests/Quests'));

const SalePage = React.lazy(() => import('./pages/SalePage/SalePage'));
const MarketPlace = React.lazy(() => import('./pages/MarketPlace'));
const InventoryPage = React.lazy(() => import('./pages/Inventory'));
const WarriorInfo = React.lazy(() =>
  import('./pages/Inventory/components/WarriorInfo'),
);
const Vesting = React.lazy(() => import('./pages/Vesting'));

const IDOPage = React.lazy(() => import('./pages/IDO'));
const StakingWarrior = React.lazy(() => import('./pages/StakingWarrior'));
const PrivacyTerm = React.lazy(() => import('./pages/PrivacyTerm'));
const Policies = React.lazy(() => import('./pages/Policies'));
const FrameDetail = React.lazy(() => import('./pages/FrameDetail'));
// const StakingToken = React.lazy(() => import('./pages/StakingToken'))
// const FrameMarket = React.lazy(() => import('./pages/FrameMarket'))
// const Staking = React.lazy(() => import('./pages/Staking'))
const StakingNFT = React.lazy(() => import('./pages/StakingNFT'));
const Tournament = React.lazy(() => import('./pages/Tournament'));
// const StakingNFT = React.lazy(() => import('./pages/StakingNFT'))
const FrameMarket = React.lazy(() => import('./pages/FrameMarket'));
// const Staking = React.lazy(() => import('./pages/Staking'))
const Mint = React.lazy(() => import('./pages/Mint'));

function App() {
  // const tagManagerArgs = {
  // 	gtmId: 'GTM-M7S5CVL',
  // }
  // React.useEffect(() => {
  // 	TagManager.initialize(tagManagerArgs)
  // 	// eslint-disable-next-line
  // }, [])
  useShowButtonScroll();
  useRouteTopPage();
  useAuthenticate();
  useStoreAccount();
  useListenWallet();
  return (
    <React.Suspense fallback={<PreLoading />}>
      <SocialFixed />
      <QrCode />
      <ScrollButton />
      <AccountDetail />
      <PreLoading />
      <Loading />
      <MenuList />
      <Navbar />
      <Connect />
      <HeaderText />
      {/* <TourBanner /> */}
      <AuthenticateForm isNewAccount={true} />
      <Switch>
        <Route component={GalleryPage} path="/gallery" exact />
        <Route component={Fusion} path="/fusion/:id" exact />
        {/* <Route component={ConvertPage} path='/bridge-token' exact /> */}
        {/* <Route component={GuilScholarPage} path='/scholar-guild' exact /> */}
        {/* <Route component={ConvertPage} path='/bridge-token' exact /> */}
        <Route component={WhitePaper} path="/white-paper" exact />
        {/* <Route component={ComingBuyPage} path='/summon-warriors' exact /> */}
        <Route component={SalePage} path="/summon-warriors" exact />
        {/* <Route component={RoadmapPage} path="/about-us" exact /> */}
        <Route component={HomePage} path="/" exact />
        <Route component={Quests} exact path="/quests" />
        <Route component={InventoryPage} name="inventory" path="/inventory" />
        <Route path="/warriors/:id" children={<WarriorInfo />} />
        <Route component={IDOPage} exact path="/buy-wal" />
        <Route component={MarketPlace} exact path="/marketplace" />
        <Route exact path="/vesting">
          <Vesting activeTab={0} />
        </Route>
        <Route exact path="/reward">
          <Vesting activeTab={1} />
        </Route>
        <Route component={StakingNFT} exact path="/staking" />
        <Route component={StakingWarrior} exact path="/staking-warriors" />
        <Route component={PrivacyTerm} exact path="/terms-of-use" />
        <Route component={Policies} exact path="/privacy-policies" />
        <Route path="/frames/:id" children={<FrameDetail />} />
        {/* <Route path='/staking-token' component={StakingToken} /> */}
        <Route component={FrameMarket} exact path="/frame-market" />
        <Route component={Tournament} exact path="/tournament" />
        <Route component={Mint} exact path="/artifacts-minting" />
        {/* <Route component={FrameMarket} exact path='/frame-market' /> */}
        <Route component={ComingPage} />
      </Switch>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Footer />
    </React.Suspense>
  );
}

export default App;
