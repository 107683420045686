export const ERROR_CODE = {
	NONE: 0,
	AUTHENTICATION_ERROR: 1,
	INVALID_ADDRESS: 7,
	SIGNATURE_INVALID: 8,
	SERVER_ERROR: 3,
	INVALID_OWNER_ADDRESS: 9,
	COULD_NOT_READ_CONTRACT: 10,
	CONFIG_NOT_AUTHORIZED: 11,
	INVALID_USERNAME_PASSWORD: 12,
	ALREADY_REGISTER_USERNAME: 13, // user đã đk account
	PLAYER_TOKEN_NOT_MATCH: 14,
	INVALID_USERNAME: 15,
	INVALID_PASSWORD: 16,
	USERNAME_HAS_REGISTERED: 17,
	EMAIL_HAS_REGISTERED: 18,
	INVALID_USERNAME_FORMAT: 19,

	HERO_EXIST: 100,
	HERO_NOT_EXIST: 101,
	HERO_TRANSFER_INVALID: 102,
	HERO_ALREADY_OWNED: 103,
	HERO_ALREADY_OPENED: 104,

	OFFER_EXIST: 200,
	OFFER_NOT_EXIST: 201,
	OFFER_ALREADY_CANCEL: 202,
	OFFER_TYPE_NOT_SUPPORT: 203,

	LISTING_EXIST: 200,
	LISTING_NOT_EXIST: 201,
	LISTING_ALREADY_CANCEL: 202,
	LISTING_TYPE_NOT_SUPPORT: 203,

	PACKAGE_REQUEST_NOT_FOUND: 300,

	HERO_NOT_OWNED: 400,
	MISSION_NOT_FOUND: 401,
	MISSION_REQUIREMENT_NOT_MEET: 402,
	CITY_NOT_FOUND: 403,
	CITY_SLOT_MAX: 404,
	CITY_SLOT_NOT_EMPTY: 405,
	HERO_ON_MISSION_OR_COOL_DOWN: 406,
	CITY_SLOT_NOLIMIT: 407,
	CITY_SLOT_EMPTY: 408,
	CITY_ATTACK_FAILED: 409,
	CITY_SLOT_NOT_EXIST: 410,
	MISSION_NOT_IN_TODAY_LIST: 411,

	HERO_ALREADY_ON_A_TEAM: 500,
	PVP_MATCH_NOT_FOUND: 501,
	NO_HEROES: 502,
	PVP_TEAM_NOT_FOUND: 503,
	PVP_TEAM_NOT_OWN: 504,
	PVP_BATTLE_NOT_FOUND: 505,
	PVP_BATTLE_ALREADY_ENDED: 506,
	PVP_BATTLE_PENDING: 507,
	TEAM_TOO_MUCH_HEROES: 508,
	NOT_ENOUGH_STAMINA: 509,
	PVP_BATTLE_HASH_NOT_MATCH: 510,
	HERO_TRANSFER_COOL_DOWN: 511,
	PVP_MATCH_NO_RUNNING_SESSION: 512,
	TEAM_NOT_VALID_TEAM: 513,
	SESSION_REGISTER_WHITELIST: 514,
	MIN_HERO_REQUIRE: 515,
	HERO_ON_LISTING: 516,

	// Game Config
	INVALID_ENV: 600,

	// Staking
	POOL_NOT_FOUND: 700,
	FRAME_NOT_OWN: 701,
	FRAME_NOT_FOUND: 702,

	// ARTIFACT
	ARTIFACT_EQUIPPED: 801,
	ARTIFACT_NOT_OWN: 802,
	ARTIFACT_TYPE_NOT_MATCH: 803,
}
