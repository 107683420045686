import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { onMoveAnimation } from 'services/useDevelopUI'
import {
	useRegister,
	useLogin,
	useLinkWallet,
	onLoginV2,
	onRegisterNewAccount,
} from 'queries/useLogin.query'
import { useCheckAccountActive, useCheckConnected } from 'services/useWalletProvider'
import { DataContext } from 'contexts/DataContext'
import './AuthenticateForm.css'
import { useTranslation } from 'react-i18next'
import { useHandleSignIn, useHandleSignInV1 } from 'services/authenticate'
import { SellOverlay } from 'pages/Inventory/components/SellOverlay'
import { toast } from 'react-toastify'
import { useGetAccountInfo } from 'queries/useMyAccount.query'

const LoginForm = ({ onTrigger, error, setError, onClose }) => {
	const { t, ready } = useTranslation()
	const [info, setInfo] = React.useState({
		username: '',
		password: '',
	})
	const onDone = (res) => {
		setError('')
		setInfo({
			username: '',
			password: '',
		})
		onMoveAnimation('authenticate-form', 'moveOutOpacity')
	}
	const onLogin = useLogin(setError, onDone)
	if (ready)
		return (
			<div className='form login-form'>
				<div className='title-container flex flex-row justify-between'>
					<span className='title'>{t('Sign in')}</span>
					<CloseIcon onClick={onClose} />
				</div>
				<div className='input-field'>
					<span>Username</span>
					<label className='filter-search flex relative'>
						<input
							value={info.username}
							onChange={(e) => setInfo({ ...info, username: e.target.value })}
							type='text'
							placeholder={t('Enter your username')}
						/>
					</label>
				</div>
				<div className='input-field'>
					<span>Password</span>
					<label className='filter-search flex relative'>
						<input
							value={info.password}
							onChange={(e) => setInfo({ ...info, password: e.target.value })}
							type='password'
							placeholder={t('Enter your password')}
						/>
					</label>
				</div>
				<span className='link-form'>
					{t(`Don't have an account?`)} <b onClick={() => onTrigger()}>{t(`Sign up`)}</b>
				</span>
				{error ? <span className='form-error'>{error}</span> : null}
				<div onClick={() => onLogin(info)} className='btn-primary'>
					{t('Login')}
				</div>
			</div>
		)
}
const RegisterForm = ({
	onTrigger,
	error,
	setError,
	onClose,
	isNewAccount,
	onSetActiveForm,
	onSetIsNewAccount,
}) => {
	const [overlayInfo, setOverlayInfo] = React.useState(null)
	const onSign = useHandleSignIn()
	const data = React.useContext(DataContext)
	const { data: accountInfo } = useGetAccountInfo()
	const { t, ready } = useTranslation()
	function onlyLettersAndSpaces(str) {
		return /^[a-z0-9_]*$/.test(str)
	}
	const isConnected = useCheckConnected()

	const onDone = () => {
		setError('')
		setInfo({
			username: '',
			password: '',
			confirm: '',
		})
		onMoveAnimation('authenticate-form', 'moveOutOpacity')
	}
	const onLinkWallet = useLinkWallet(setError, onDone)
	const [info, setInfo] = React.useState({
		username: '',
		password: '',
		confirm: '',
	})
	const onHandleSignInV1 = useHandleSignInV1((a, b) => {
		const signatureConsume = typeof b === 'string' ? b : b.result
		onLinkWallet(signatureConsume, a?.data?.token)
	})

	const onRegister = useRegister(setError, (res) => {
		setError('')
		setInfo({
			username: '',
			password: '',
			confirm: '',
		})
		onMoveAnimation('authenticate-form', 'moveOutOpacity')
	})
	const onHandleLinkWallet = async (isNew) => {
		let loginInfo
		if (isNew) {
			loginInfo = await onLoginV2(info)
		} else {
			loginInfo = await onRegisterNewAccount(info)
		}
		if (loginInfo) {
			if (loginInfo?.error_code === 0) {
				if (loginInfo?.data?.game_data?.address) {
					toast.error('Account already linked wallet')
				} else onHandleSignInV1(loginInfo)
			} else {
				toast.error(`Something went wrong with error code ${loginInfo?.error_code}`)
			}
		}

		// onLinkWallet(signature, loginInfo)
	}
	if (ready)
		return (
			<div className='form login-form'>
				<SellOverlay
					onClose={() => onMoveAnimation('sellwarrior-overlay102', 'moveOutOpacity')}
					tiltle={overlayInfo?.tiltle}
					onConfirm={overlayInfo?.onConfirm}
					moreInfo={overlayInfo?.moreInfo}
					type={overlayInfo?.type}
					max_price={overlayInfo?.max_price}
					_id='102'
				></SellOverlay>
				<div className='title-container flex flex-row justify-between'>
					<span className='title'>
						{isNewAccount ? t('Link to existing account') : t('Sign up')}
					</span>
					<CloseIcon onClick={onClose} />
				</div>
				{/* <div className='input-field'>
				<span>Email</span>
				<label className='filter-search flex relative mt-2'>
					<input
						autoComplete='off'
						placeholder='Enter your email'
						value={info.email}
						type='email'
						onChange={(e) => {
							setInfo({ ...info, email: e.target.value })
						}}
					/>
				</label>
			</div> */}
				<div className='input-field'>
					<span>{t('Username')}</span>
					<label className='filter-search flex relative mt-2'>
						<input
							autoComplete='false'
							placeholder={t('Enter your username')}
							value={info?.username}
							type='text'
							onChange={(e) => {
								onlyLettersAndSpaces(e.target.value?.toLowerCase())
									? setError('')
									: setError(t(`Invalid username (only allow: lowercase alphabet , numeric , "_")`))
								setInfo({ ...info, username: e.target.value?.toLowerCase() })
							}}
						/>
					</label>
				</div>
				<div className='input-field'>
					<span>{t('Password')}</span>
					<label className='filter-search flex relative mt-2'>
						<input
							autoComplete='new-password'
							type='password'
							placeholder={t('Enter your password')}
							defaultValue={info.password}
							onChange={(e) => {
								setInfo({ ...info, password: e.target.value })
							}}
						/>
					</label>
				</div>
				{isNewAccount ? null : (
					<div className='input-field'>
						<span>{t('Confirm Password')}</span>
						<label className='filter-search flex relative mt-2'>
							<input
								autoComplete='off'
								type='password'
								placeholder={t('Enter your password')}
								value={info.confirm}
								onChange={(e) => {
									setInfo({ ...info, confirm: e.target.value })
								}}
							/>
						</label>
					</div>
				)}
				{error ? <span className='form-error'>{error}</span> : null}
				{isConnected ? (
					<div className='flex items-center justify-center mt-4'>
						{accountInfo?.player ? null : (
							<div
								className='btn btn-secondary mr-2 cursor-pointer'
								onClick={() => {
									onSetActiveForm(3)
								}}
							>
								Back
							</div>
						)}
						<div
							onClick={() => {
								if (isNewAccount) {
									onHandleLinkWallet(true)
								} else {
									if (accountInfo?.player) {
										if (!data?.user?.token) onSign(true)
										else {
											onRegister(info, data?.user?.token)
										}
									} else {
										onHandleLinkWallet(false)
									}
									// if (onlyLettersAndSpaces(info?.username)) onRegister(info, data?.user?.token)
									// else
									// 	setError(
									// 		t('Invalid username (should only contain lowercase or numeric letters)')
									// 	)
								}
							}}
							className='btn-primary ml-2 btn'
						>
							{isNewAccount ? t('Link') : t('Sign up')}
						</div>
					</div>
				) : (
					<div
						onClick={() => onMoveAnimation('connect-modal', 'moveInOpacity')}
						className='btn-primary'
					>
						{t('Connect')}
					</div>
				)}
				{isNewAccount || accountInfo?.player ? null : (
					<div
						className='btn-skip'
						onClick={() => {
							setOverlayInfo({
								tiltle: 'CONFIRMATION',
								onConfirm: (price) => {
									onMoveAnimation('authenticate-form', 'moveOutOpacity')
									onMoveAnimation('sellwarrior-overlay102', 'moveOutOpacity')
									onSetActiveForm(3)
									onSetIsNewAccount(false)
									onSign(true)
								},
								moreInfo: `Please authorize signature on your wallet to skip registration step`,
								type: 15,
							})
							onMoveAnimation('sellwarrior-overlay102', 'moveInOpacity')
						}}
					>
						Register game account later
					</div>
				)}
			</div>
		)
}
const RegisterOption = (props) => {
	const { onSetActiveForm, onSetIsNewAccount } = props
	const [overlayInfo, setOverlayInfo] = React.useState(null)
	return (
		<div className='register-option relative'>
			<SellOverlay
				onClose={() => onMoveAnimation('sellwarrior-overlay101', 'moveOutOpacity')}
				tiltle={overlayInfo?.tiltle}
				onConfirm={overlayInfo?.onConfirm}
				moreInfo={overlayInfo?.moreInfo}
				type={overlayInfo?.type}
				max_price={overlayInfo?.max_price}
				_id='101'
			></SellOverlay>
			<div className='title'>YOU ARE ALMOST THERE!</div>
			<div className='option-close cursor-pointer'>
				<CloseIcon
					onClick={() => {
						onMoveAnimation('authenticate-form', 'moveOutOpacity')
						onSetActiveForm(3)
						onSetIsNewAccount(false)
					}}
				/>
			</div>
			<div className='subtitle'>Connect your game account to continue on The Wasted Lands</div>
			<div
				className='btn-1'
				onClick={() => {
					onSetActiveForm(1)
					onSetIsNewAccount(true)
				}}
			>
				LINK TO EXISTING GAME ACCOUNT
			</div>
			<div
				className='btn-2'
				onClick={() => {
					setOverlayInfo({
						tiltle: 'CONFIRMATION',
						onConfirm: (price) => {
							onSetActiveForm(1)
							onSetIsNewAccount(false)
							onMoveAnimation('sellwarrior-overlay101', 'moveOutOpacity')
						},
						moreInfo: `Confirm to register new game account. 
						This action cannot be undone`,
						type: 15,
					})
					onMoveAnimation('sellwarrior-overlay101', 'moveInOpacity')
				}}
			>
				CREATE NEW GAME ACCOUNT
			</div>
		</div>
	)
}

export const AuthenticateForm = (props) => {
	const [activeForm, setActiveForm] = React.useState(3)
	const [isNewAccount, setIsNewAccount] = React.useState(false)
	const account = useCheckAccountActive()
	const { data: accountInfo } = useGetAccountInfo()
	React.useEffect(() => {
		setActiveForm(3)
	}, [account])
	React.useEffect(() => {
		if (accountInfo?.player && accountInfo) {
			if (activeForm === 3 || activeForm === 11) setActiveForm(11)
		} else {
			// setActiveForm(3)
		} // eslint-disable-next-line
	}, [accountInfo, activeForm])

	const [error, setError] = React.useState('')
	return (
		<div id='authenticate-form' className='authenticate-form'>
			<div
				onClick={() => {
					onMoveAnimation('authenticate-form', 'moveOutOpacity')
					setError('')
					setActiveForm(3)
					setIsNewAccount(false)
				}}
				className='overlay'
			/>
			{activeForm === 0 ? (
				<LoginForm
					onClose={() => {
						onMoveAnimation('authenticate-form', 'moveOutOpacity')
						setError('')
						setActiveForm(3)
						setIsNewAccount(false)
					}}
					onTrigger={() => setActiveForm(1)}
					error={error}
					setError={setError}
				/>
			) : activeForm === 1 || activeForm === 11 ? (
				<RegisterForm
					isNewAccount={isNewAccount}
					onClose={() => {
						onMoveAnimation('authenticate-form', 'moveOutOpacity')
						setError('')
						setActiveForm(3)
						setIsNewAccount(false)
					}}
					onTrigger={() => setActiveForm(0)}
					error={error}
					setError={setError}
					onSetActiveForm={setActiveForm}
					onSetIsNewAccount={setIsNewAccount}
				/>
			) : (
				<RegisterOption onSetActiveForm={setActiveForm} onSetIsNewAccount={setIsNewAccount} />
			)}
		</div>
	)
}
