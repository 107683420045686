import random from 'lodash/random'
const nodes = [
	process.env.REACT_APP_NODE_1,
	process.env.REACT_APP_NODE_1,
	process.env.REACT_APP_NODE_1,
]
const nodesETH = [process.env.REACT_APP_NODE_4]

export const getNodeUrl = (_isETH = false) => {
	if (_isETH) {
		const randomIndex = random(0, nodesETH.length - 1)
		return nodesETH[randomIndex]
	}
	const randomIndex = random(0, nodes.length - 1)
	return nodes[randomIndex]
}
