export const REQUEST_LOADING = 'REQUEST_LOADING'
export const REQUEST_UNLOAD = 'REQUEST_UNLOAD'
export const REQUEST_TXHASH = 'REQUEST_TXHASH'
export const REQUEST_STATUS = 'REQUEST_STATUS'

//Message actions
export const PUSH_ERROR_MESSAGE = 'PUSH_ERROR_MESSAGE'
export const PUSH_SUCCESS_MESSAGE = 'PUSH_SUCCESS_MESSAGE'
export const PUSH_INFO_MESSAGE = 'PUSH_INFO_MESSAGE'
export const POP_MESSAGE = 'POP_MESSAGE'
