import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { RefreshContextProvider } from 'contexts/RefreshContext'
import { DataContext } from 'contexts/DataContext'
import { Provider as ReduxProvider } from 'react-redux'
import { store } from 'services/redux/store'
import { BrowserRouter as Router } from 'react-router-dom'
import { UseWalletProvider as WalletProvider } from 'use-wallet'
import { SUPPORTED_CHAINID } from 'configs/Connect/Configs.networks'
import rpc from 'configs/Connect/Configs.rpc'

const queryClient = new QueryClient()

function Providers({ children }) {
	const [count, setCount] = React.useState(0)
	const [user, setUser] = React.useState(null)
	const [account, setAccount] = React.useState(null)
	const [isConnectWL, setIsConnectWL] = React.useState(false)
	return (
		<WalletProvider
			autoConnect={true}
			connectors={{
				injected: {
					// allows you to connect and switch between mainnet and rinkeby within Metamask.
					chainId: SUPPORTED_CHAINID,
				},
				fortmatic: {
					chainId: SUPPORTED_CHAINID,
					apiKey: '',
				},
				portis: {
					dAppId: '',
					chainId: SUPPORTED_CHAINID,
				},
				walletconnect: {
					rpc,
					bridge: 'https://bridge.walletconnect.org',
					pollingInterval: 12000,
				},
				walletlink: {
					chainId: [1, 56],
					url: 'https://eth-mainnet.alchemyapi.io/v2/q1gSNoSMEzJms47Qn93f9-9Xg5clkmEC',
				},
			}}
		>
			<Router>
				<QueryClientProvider client={queryClient}>
					<RefreshContextProvider>
						<ReduxProvider store={store}>
							<DataContext.Provider
								value={{
									count,
									setCount,
									user,
									setUser,
									isConnectWL,
									setIsConnectWL,
									account,
									setAccount,
								}}
							>
								{children}
							</DataContext.Provider>
						</ReduxProvider>
					</RefreshContextProvider>
				</QueryClientProvider>
			</Router>
		</WalletProvider>
	)
}

export default Providers
