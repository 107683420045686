import { REQUEST_LOADING, REQUEST_UNLOAD, REQUEST_STATUS } from 'constants/actions'
import { onMoveAnimation } from 'services/useDevelopUI'

export const requestLoading = (payload = {}) => {
	onMoveAnimation('loading', 'moveInOpacity')
	return {
		type: REQUEST_LOADING,
		payload: {
			txHash: payload?.txHash,
			message: payload?.message,
			status: payload?.status,
		},
	}
}
export const requestUnload = () => {
	onMoveAnimation('loading', 'moveOutOpacity')
	return {
		type: REQUEST_UNLOAD,
		payload: {
			txHash: '',
			message: '',
			status: '',
		},
	}
}
export const requestStatus = (payload = {}) => {
	return {
		type: REQUEST_STATUS,
		payload: {
			txHash: payload?.txHash,
			message: payload?.message,
			status: payload?.status,
		},
	}
}
