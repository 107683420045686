import React from 'react'
import { useCheckAccountActive, useDisconnectWallet } from 'services/useWalletProvider'
import { useGetBalance } from 'services/useWalletProvider'
import LaunchIcon from '@material-ui/icons/Launch'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import QrCodeIcon from '@mui/icons-material/QrCode'
import { onMoveAnimation } from 'services/useDevelopUI'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'
import { DataContext } from 'contexts/DataContext'
import { useCopyToClipBoard } from 'services/utils'
import { useHandleSignIn } from 'services/authenticate'
import './Account.css'
import LogoBNB from '../../../assets/nav-asset/bnb.svg'
import { useTranslation } from 'react-i18next'

export const AccountDetail = () => {
	const { t, ready } = useTranslation()
	const account = useCheckAccountActive()
	const { data: balance } = useGetBalance()
	const onCopyToClipBoard = useCopyToClipBoard()
	const onDisconnectWallet = useDisconnectWallet()
	const data = React.useContext(DataContext)
	const signin = useHandleSignIn()
	if (ready)
		return (
			<div
				id='account-info'
				className='fixed inset-0 z-10 flex items-center justify-center invisible w-screen h-screen text-white opacity-0 account-detail'
			>
				<div
					onClick={() => onMoveAnimation('account-info', 'moveOutOpacity')}
					className='overlay'
				></div>
				<div className='px-8 py-11 rounded-xl form-detail'>
					<div className='flex flex-row items-center justify-between form-detail__account-address'>
						<span className='text-xl font-semibold '>
							{account?.length !== 0
								? account?.substr(0, 8) +
								  '...' +
								  account?.substr(account.length - 8, account.length)
								: 'No account selected'}
						</span>
						<div className='flex flex-row items-center justify-center'>
							<div className='mr-4 online-icon'></div>
							BSC
						</div>
					</div>
					<div className='flex flex-row justify-between p-4 mt-4 font-semibold text-right rounded-xl balance-info'>
						<div className='flex flex-row items-center text-lg'>
							<img className='mr-2 text-sm logo-binance' src={LogoBNB} alt='Binance Logo' />
							<span className=''>BNB</span>
						</div>
						<div className='flex flex-col text-end'>
							<span className='text-lg'>{parseFloat(balance).toFixed(2)}</span>
							{/* <span className='font-normal'>$0</span> */}
						</div>
					</div>
					<div className='form-btn-list '>
						<div
							onClick={() => window.open(`https://bscscan.com/address/${account}`, '_blank')}
							className='flex flex-col items-center justify-center flex-1 py-4 mr-4 transition-all bg-gray-700 cursor-pointer btn-account rounded-xl hover:bg-gray-600'
						>
							<LaunchIcon className='mb-2 form-btn-icon' />
							{t('View')}
						</div>
						<div
							onClick={() => onCopyToClipBoard(account)}
							className='flex flex-col items-center justify-center flex-1 py-4 mr-4 transition-all bg-gray-700 cursor-pointer btn-account rounded-xl hover:bg-gray-600'
						>
							<FileCopyIcon className='mb-2 form-btn-icon' />
							{t('Copy')}
						</div>
						<div
							onClick={() => {
								if (data.user) {
									onMoveAnimation('account-info', 'moveOutOpacity')
									onMoveAnimation('qr-code', 'moveInOpacity')
								} else {
									signin()
								}
							}}
							className='flex flex-col items-center justify-center flex-1 py-4 mr-4 transition-all bg-gray-700 cursor-pointer btn-account rounded-xl hover:bg-gray-600'
						>
							<QrCodeIcon className='mb-2 form-btn-icon' />
							{t('QR Code')}
						</div>
						<div
							onClick={() => {
								onMoveAnimation('account-info', 'moveOutOpacity')
								onDisconnectWallet()
							}}
							style={{ color: 'red', flex: 1.2 }}
							className='flex flex-col items-center justify-center py-4 transition-all bg-gray-700 cursor-pointer flex-2 btn-account rounded-xl hover:bg-gray-600'
						>
							<PowerSettingsNewIcon className='mb-2 form-btn-icon' />
							{t('Disconnect')}
						</div>
					</div>
				</div>
			</div>
		)
}
