/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { DataContext } from 'contexts/DataContext'
import { useCheckAccountActive } from './useWalletProvider'
import { useHandleSignIn } from './authenticate'
import { useGetAccountInfo } from 'queries/useMyAccount.query'
import { onMoveAnimation } from './useDevelopUI'

export const useAuthenticate = () => {
	const data = React.useContext(DataContext)
	const account = useCheckAccountActive()
	const { data: accountInfo } = useGetAccountInfo()
	const sigin = useHandleSignIn()
	React.useEffect(() => {
		if (account && accountInfo) {
			if (!accountInfo?.player) {
				onMoveAnimation('authenticate-form', 'moveInOpacity')
			} else {
				const user_key = JSON.parse(localStorage.getItem('user_key') || '[]')
				const user = user_key.find(
					(userKey) => userKey.address.toLowerCase() === account?.toLowerCase()
				)
				if (user) {
					data?.setUser(user)
				} else {
					data?.setUser(null)
					sigin(true)
				}
			}
		}
	}, [accountInfo])
}
