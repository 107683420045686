import React from 'react'
import QRCode from 'react-qr-code'
import { DataContext } from 'contexts/DataContext'
import { onMoveAnimation } from 'services/useDevelopUI'
import { useCopyToClipBoard } from 'services/utils'
import './QrCode.css'
import { useTranslation } from 'react-i18next'

export const QrCode = () => {
	const { t, ready } = useTranslation()
	const data = React.useContext(DataContext)
	const onCopy = useCopyToClipBoard()
	if (ready)
		return (
			<div id='qr-code' className='qr-form'>
				<div onClick={() => onMoveAnimation('qr-code', 'moveOutOpacity')} className='overlay'></div>
				<div className='qr-code-container'>
					<span className='title'>{t('Scan me')}</span>
					<div className='qr-container'>
						<QRCode value={data.user?.token || t('Something went wrong')} />
					</div>
					<span className='token w-full hidden-mobile'>{data.user?.token}</span>
					<div
						onClick={() => onCopy(data.user?.token || t('Something went wrong'))}
						className='qr-token btn-primary'
					>
						{t('Copy token')}
					</div>
				</div>
			</div>
		)
}
