import React from 'react'
import './Loading.css'
import { store } from 'services/redux/store'
import { requestUnload } from 'services/redux/loading/actions'
import { useDispatch } from 'react-redux'
import { useCopyToClipBoard } from 'services/utils'
import DoneIcon from '@mui/icons-material/Done'
import ErrorIcon from '@mui/icons-material/Error'
import CloseIcon from '@mui/icons-material/Close'

export const Loading = () => {
	const onCopyToClipBoard = useCopyToClipBoard()
	const dispatch = useDispatch()
	const [txHash, setTxHash] = React.useState('')
	const [message, setMessage] = React.useState('')
	const [status, setStatus] = React.useState('')
	store.subscribe(() => {
		setTxHash(store.getState()?.loadingReducer?.txHash)
		setMessage(store.getState()?.loadingReducer?.message)
		setStatus(store.getState()?.loadingReducer?.status)
	})

	return (
		<div
			style={{ zIndex: 10000000 }}
			id='loading'
			className='fixed inset-0 flex items-center justify-center invisible w-screen h-screen text-white opacity-0 '
		>
			<div className='overlay'></div>
			<div
				style={{ backgroundColor: '#161519', fontFamily: 'Montserrat' }}
				className='flex flex-col items-center justify-center w-full'
			>
				<div className='loading-container absolute'>
					<div
						className='btn-close cursor-pointer'
						onClick={() => {
							dispatch(requestUnload())
						}}
					>
						{' '}
						<CloseIcon />
					</div>
					<div className='loading-gift absolute flex flex-col items-center justify-center'>
						{status === '' ? (
							<div class='lds-ring'>
								<div></div>
								<div></div>
								<div></div>
								<div></div>
							</div>
						) : status ? (
							<div className='loading__success loading_status'>
								<DoneIcon />
							</div>
						) : (
							<div className='loading__error loading_status'>
								<ErrorIcon />
							</div>
						)}
					</div>
					<div className='loading-info flex flex-col items-center'>
						{txHash !== '' ? (
							<div className='mb-4 loading__txhash relative '>
								<div className='loading__inform'>Click to copy</div>
								<div
									className='tx_hash cursor-pointer'
									onClick={() => {
										onCopyToClipBoard(txHash)
									}}
								>
									{txHash}
								</div>
							</div>
						) : null}
						<div className='text-center mb-4'>Message: {message}</div>

						{/* <div
								className='btn-secondary cursor-pointer'
								onClick={() => {
									dispatch(requestUnload())
								}}
							>
								{t('Close')}
							</div> */}
						<div className='logo'>
							<img src={require('../../assets/GuidLogo.png').default} alt='' />
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
