import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { onMoveAnimation } from 'services/useDevelopUI'
import { useGetAccountInfo } from 'queries/useMyAccount.query'
import { DataContext } from 'contexts/DataContext'
import { Link } from 'react-router-dom'
import { useCheckAccountActive } from 'services/useWalletProvider'
import { useHandleSignIn } from 'services/authenticate'
import './HeaderText.css'
import { useTranslation } from 'react-i18next'

export const HeaderText = () => {
	const { t, ready } = useTranslation()
	const onSignin = useHandleSignIn()
	const [isConnectModal, setIsConnectModal] = React.useState(false)
	const account = useCheckAccountActive()
	const data = React.useContext(DataContext)
	const { data: accountInfo } = useGetAccountInfo()
	React.useEffect(() => {
		if (isConnectModal && account) {
			setTimeout(() => {
				if (!accountInfo?.player) {
					onMoveAnimation('authenticate-form', 'moveInOpacity')
					setIsConnectModal(false)
				} else if (!data.user) {
					onSignin(true)
				} else if (!accountInfo?.player?.username) {
					onMoveAnimation('authenticate-form', 'moveInOpacity')
					setIsConnectModal(false)
				}
			}, 1000)
		} // eslint-disable-next-line
	}, [isConnectModal, accountInfo, data?.user])
	if (ready)
		return (
			<div id='header-text' className='header-text hidden'>
				<CloseIcon
					onClick={() => onMoveAnimation('header-text', 'moveOutOpacity')}
					style={{ position: 'absolute', top: '10px', right: '20px', cursor: 'pointer' }}
				/>
				{!accountInfo?.player?.username || !data?.user ? (
					<>
						<span className='title'>
							{t(`Official Season 1 starts now with 250,000 $WAL rewards pool!`)}{' '}
							<span
								className='cursor-pointer whitespace-nowrap'
								style={{ color: '#b14c12' }}
								onClick={() => {
									if (account) {
										onMoveAnimation('authenticate-form', 'moveInOpacity')
									} else {
										onMoveAnimation('connect-modal', 'moveInOpacity')
										setIsConnectModal(true)
									}
								}}
							>
								<span className='underline'>{t('Register to join now')}</span> {'>'}
							</span>
						</span>
						<span className='title'>
							{t(`$WAL token is listed for trading!`)}{' '}
							<span className='whitespace-nowrap'>
								<Link to='/buy-wal'>{t('Start trading now!')}</Link>
								{' >'}
							</span>
						</span>
					</>
				) : accountInfo?.game_data?.beta_status !== 1 ? (
					<>
						<span className='title'>
							{t('You need at least 3 NFTs to join Alpha Test season!')}{' '}
							<span className='whitespace-nowrap'>
								<Link to='/marketplace'>{t('Go to marketplace')}</Link>
								{' >'}
							</span>
						</span>
						<span className='title'>
							{t('$WAL token is listed for trading!')}{' '}
							<span className='whitespace-nowrap'>
								<Link to='/buy-wal'>{t('Start trading now!')}</Link>
								{' >'}
							</span>
						</span>
					</>
				) : (
					<>
						<span className='title'>
							{t(`Official Season 1 starts now with 250,000 $WAL rewards pool!`)}{' '}
						</span>
						<span className='title'>
							{t(`$WAL token is listed for trading!`)}{' '}
							<span className='whitespace-nowrap'>
								<Link to='/buy-wal'>{t('Start trading now!')}</Link>
								{' >'}
							</span>
						</span>
						<span className='subtitle'>
							{/* Official Telegram:{' '}
						<a href='https://t.me/TheWastedLands' target='_blank' rel='noreferrer'>
							@TheWastedLands
						</a>{' '}
						&{' '}
						<a href='https://t.me/TheWastedLandsNews' target='_blank' rel='noreferrer'>
							@TheWastedLandsNews
						</a>
						, Official Twitter:{' '}
						<a href='https://twitter.com/thewastedlands' target='_blank' rel='noreferrer'>
							@thewastedlands
						</a>
						, Official Discord:{' '}
						<a href='https://discord.gg/thewastedlands' target='_blank' rel='noreferrer'>
							thewastedlands
						</a> */}
						</span>{' '}
					</>
				)}
			</div>
		)
}
