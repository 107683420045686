import React from 'react'
import './SellOverlay.css'
import { Remove as MinusIcon, Add as PlusIcon } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
/**
 * @author
 * @function SellOverlay
 **/

export const SellOverlay = (props) => {
	const { t, ready } = useTranslation()
	const {
		onClose,
		tiltle,
		onConfirm,
		moreInfo,
		type,
		minPrice,
		warType,
		maxQuantity,
		max_price,
		_id,
		defaultAcc,
		_inform,
		minStakePrice,
		stakeText,
	} = props
	const _minPrice = minPrice ? minPrice : 0
	const _maxQuantity = maxQuantity ? maxQuantity : 0
	const _warType = warType ? warType : -1
	const [inform, setInform] = React.useState('')
	React.useEffect(() => {
		setInform(_inform)
	}, [_inform])
	const messageIn = (warType, minPrice) => {
		switch (warType) {
			case -1:
				return 'Invalid input'

			case 1:
				return `Normal Warrior floor price is starting from ${minPrice} WAL`
			case 2:
				return `Rare Warrior floor price is starting from ${minPrice} WAL`
			case 3:
				return `Epic Warrior floor price is starting from ${minPrice} WAL`
			case 4:
				return `Mystic Warrior floor price is starting from ${minPrice} WAL`
			default:
				return 'Invalid input'
		}
	}
	const [price, setPrice] = React.useState('')

	const [quantity, setQuantity] = React.useState(1)
	const [isDefault, setIsDefault] = React.useState(true)
	if (ready)
		return (
			<div id={`sellwarrior-overlay${_id ? _id : ''}`} className='buy-overlay'>
				<div
					onClick={() => {
						onClose()
						setInform('')
						setPrice('')
						setQuantity(1)
					}}
					className='overlay'
				></div>
				<div className='buy-form flex items-center flex-col'>
					<div className='title'>
						<span>{tiltle}</span>
					</div>
					{moreInfo === '' ? (
						<div className='mt-10 mb-4 flex items-center '>
							<span className='mr-2'>
								{type === 1 || type === 5
									? t('Price:')
									: type === 10
									? t('Amount')
									: type === 100
									? t('Amount')
									: type === 15 || type === 111
									? t('Amount')
									: t('To')}
							</span>
							<label className='filter-search flex'>
								<input
									type={type !== 10 || type !== 15 || type !== 111 ? 'text' : 'number'}
									placeholder={
										type === 1
											? t('Your expected price')
											: type === 10
											? t('Your transfer number')
											: type === 100
											? t('Amount ticket')
											: type === 15
											? t('Amount')
											: type === 111
											? t('Amount')
											: type === 5
											? t('Expected total price')
											: t('Transfer to')
									}
									className=''
									value={isDefault && defaultAcc ? defaultAcc : price}
									onChange={(e) => {
										let res = parseFloat(e.target.value).toString()
										setIsDefault(false)
										if (type === 15) {
											if (
												res === e.target.value ||
												res + '.' === e.target.value ||
												e.target.value === ''
											)
												setPrice(
													Math.min(parseFloat(e.target.value), max_price)
														? Math.min(parseFloat(e.target.value), max_price)
														: ''
												)
										} else if (type === 111) {
											if (
												res === e.target.value ||
												res + '.' === e.target.value ||
												e.target.value === ''
											) {
												setPrice(e.target.value)
											}
										} else if (type === 1 || type === 5) {
											if (
												res === e.target.value ||
												res + '.' === e.target.value ||
												e.target.value === ''
											)
												setPrice(e.target.value)
										} else if (type === 100) {
											if (res === e.target.value || e.target.value === '') setPrice(e.target.value)
										} else setPrice(e.target.value)
									}}
								/>
								<span>
									{type === 1 || type === 5 ? '$WAL' : type === 15 || type === 111 ? '$WAL' : ''}
								</span>
							</label>
						</div>
					) : (
						<div className='mt-10 mb-4 items-center moreinfo text-center'>{moreInfo}</div>
					)}
					<div className='inform mb-6'>{inform}</div>
					{type === 5 || type === 125 ? (
						<div className='flex flex-row quantity mb-8 justify-between items-center'>
							<span className='quantity__number mr-2'>Quantity</span>
							<div className='flex flex-row amount'>
								<div
									onClick={() => {
										if (quantity > 1) {
											setQuantity(quantity - 1)
										}
									}}
									className={`container-icon cursor-pointer flex flex-col items-center justify-center ${
										quantity === 1 && 'opacity-50'
									}`}
								>
									<MinusIcon className='icon' />
								</div>
								<span className='mx-2'>{quantity}</span>
								<div
									onClick={() => {
										if (quantity < _maxQuantity) setQuantity(quantity + 1)
									}}
									className={`container-icon cursor-pointer flex flex-col items-center justify-center ${
										quantity === _maxQuantity && 'opacity-50'
									}`}
								>
									<PlusIcon className='icon' />
								</div>
							</div>
						</div>
					) : null}
					<div className='flex flex-row justify-center'>
						<div
							onClick={() => {
								setIsDefault(true)
								if (moreInfo === '') {
									if (type === 1) {
										if (parseFloat(price) >= _minPrice) {
											setInform('')
											onConfirm(price)
											setPrice('')
											setQuantity(1)
										} else setInform(t(messageIn(_warType, _minPrice)))
									} else if (type === 2 && price !== '') {
										onConfirm(price)
										setInform('')
									} else if (type === 12) {
										onConfirm(price)
										setInform('')
									} else if ((type === 5 && price !== '') || type === 125) {
										onConfirm(price, quantity)
										setPrice('')
										setQuantity(1)
									} else if (type === 10) {
										onConfirm(price)
										setInform('')
										setPrice('')
										setQuantity(1)
									} else if (type === 15) {
										onConfirm(price)
										setInform('')
										setPrice('')
										setQuantity(1)
									} else if (type === 111) {
										if (price >= minStakePrice) {
											onConfirm(price)
											setInform('')
											setPrice('')
											setQuantity(1)
										} else if (isDefault) {
											onConfirm(defaultAcc)
											setInform('')
											setPrice('')
											setQuantity(1)
										} else {
											setInform(stakeText)
											setPrice('')
										}
									} else {
										onConfirm(price)
										setInform('')
										setPrice('')
										setQuantity(1)
									}
								} else {
									onConfirm(price)
									setInform('')
									setPrice('')
									setQuantity(1)
								}
							}}
							className='mr-4 cursor-pointer btn btn-primary'
						>
							{t('Confirm')}
						</div>
						<div
							onClick={() => {
								setIsDefault(true)
								onClose()
								setInform('')
								setPrice('')
								setQuantity(1)
							}}
							className='cursor-pointer btn btn-secondary'
						>
							{t('Cancel')}
						</div>
					</div>
				</div>
			</div>
		)
}
