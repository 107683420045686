import TelegramIcon from 'assets/home/telegram__icon.png';
import DiscordIcon from 'assets/home/discord__icon.png';
import TwitterIcon from 'assets/home/twitter__icon.png';
import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
import ComputerIcon from '@mui/icons-material/Computer';
import './Social.css';
import { useTranslation } from 'react-i18next';
import LanguageIcon from '@mui/icons-material/Language';

const socialInfo = [
  {
    title: 'Telegram',
    icon: TelegramIcon,
    href: 'https://t.me/TheWastedLands',
    clickClass: 'click_telegram',
  },
  {
    title: 'Discord',
    icon: DiscordIcon,
    href: 'https://discord.gg/2KbPkdGSYy',
    clickClass: 'click_discord',
  },
  {
    title: 'Twitter',
    icon: TwitterIcon,
    href: 'https://twitter.com/thewastedlands',
    clickClass: 'click_twiter',
  },
];

export const SocialFixed = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="social-fixed">
      <div
        className="social relative"
        onClick={() => {
          window.open(
            'https://play.google.com/store/apps/details?id=com.the.wasted.lands',
            '_blank',
          );
        }}
      >
        <div className="link-qr">
          <div className="qrr">
            <img
              src={require('assets/home/google_play_store.png')?.default}
              alt=""
            />
          </div>
          <div className="scan-text">Scan to play</div>
        </div>
        <AndroidIcon />
        <span>Android</span>
      </div>
      <div
        className="social relative"
        onClick={() => {
          window.open(
            'https://apps.apple.com/vn/app/wal-kingdom/id1607067708',
            '_blank',
          );
        }}
      >
        <div className="link-qr">
          <div className="qrr">
            <img
              src={require('assets/home/apple_app_store.png')?.default}
              alt=""
            />
          </div>
          <div className="scan-text">Scan to play</div>
        </div>
        <AppleIcon />
        <span>iOS</span>
      </div>
      <div
        className="social relative"
        onClick={() => {
          window.open('https://play.thewastedlands.io/', '_blank');
        }}
      >
        <ComputerIcon />
        <span>PC</span>
      </div>
      {socialInfo.map((social, key) => (
        <div
          key={key}
          onClick={() => window.open(social.href, '_blank')}
          className="social"
        >
          <img
            src={social.icon}
            alt="Icon"
            className={`${social.clickClass}`}
          />
          <span className={`${social.clickClass}`}>{t(social.title)}</span>
        </div>
      ))}
      <div className="social relative show-560">
        <LanguageIcon />
        <span className="ml-1">{`${
          i18n.language === 'en' ? 'English' : t('Chinese')
        }`}</span>
        <div className="nav-hover ">
          <ul>
            <li
              className="nav-link"
              onClick={() => {
                i18n.changeLanguage('en');
              }}
            >
              {'English'}
            </li>
            <li
              className="nav-link mt-2"
              onClick={() => {
                i18n.changeLanguage('cn');
              }}
            >
              {t('Chinese')}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
