import React from 'react';
import Logo from 'assets/home/footer_logo.png';
import FooterBorder from 'assets/footer_border.png';
import TelegramIcon from 'assets/home/telegram__icon.png';
import DiscordIcon from 'assets/home/discord__icon.png';
import TwitterIcon from 'assets/home/twitter__icon.png';
import { Link, useLocation } from 'react-router-dom';
import '../../pages/Home/components/Footer/Footer.css';
import { useTranslation } from 'react-i18next';

export const Footer = () => {
  const { t, ready } = useTranslation();
  const location = useLocation();
  if (ready)
    return (
      <div className="">
        <div className="footer-social__container">
          {location.pathname !== '/white-paper' ? (
            <img className="footer__border" src={FooterBorder} alt="Border" />
          ) : null}
          <div className="footer-social items=center justify-center">
            <div className="part part__1">
              <img className="logo" src={Logo} alt="Logo" />
              <span className="content">
                {t('Copyright © 2021 Wasted Lands')}
              </span>
            </div>
            <div className="part part__2">
              <span className="title">{t('Contact')}</span>
              <span className="content">contact@thewastedlands.io</span>
            </div>
            <div className="flex flex-row container-social__2">
              <div className="part part__3">
                <span className="title">{t('About Us')}</span>
                <Link className="content" to="/privacy-policies">
                  {t('Privacy Policies')}
                </Link>
                <Link className="content" to="/terms-of-use">
                  {t('Terms Of Use')}
                </Link>
                <Link className="content" to="/about-us">
                  {t('Tokenomics')}
                </Link>
                <Link className="content" to="/about-us">
                  {t('Our Team')}
                </Link>
                <a className="content" href="/white-paper">
                  {t('White Paper')}
                </a>
              </div>
              <div className="part part__4">
                <span className="title">{t('Social')}</span>
                <a
                  className="content click_telegram"
                  target="_blank"
                  rel="noreferrer"
                  href="https://t.me/TheWastedLands"
                >
                  <img
                    className="click_telegram"
                    src={TelegramIcon}
                    alt="Telegram icon"
                  />{' '}
                  {t('Telegram')}
                </a>
                <a
                  className="content click_discord"
                  target="_blank"
                  rel="noreferrer"
                  href="https://discord.gg/2KbPkdGSYy"
                >
                  <img
                    className="click_discord"
                    src={DiscordIcon}
                    alt="Telegram icon"
                  />{' '}
                  {t('Discord')}
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="content click_twiter"
                  href="https://twitter.com/thewastedlands"
                >
                  <img
                    src={TwitterIcon}
                    alt="Telegram icon"
                    className="click_twiter"
                  />{' '}
                  {t('Twitter')}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};
